// import classNames from 'classnames';
import Konva from 'konva';
import React, { Component } from 'react';
import { Layer, Rect, Text } from 'react-konva';

const Axes = ({XAxisProperties, YAxisProperties,
   openModal, stageChangeCursor, setEnableSearchBox}) => {
  class Axes extends Component {    

      render() {
        var measureTextSizeHelper = new Konva.Text({
          x: 0,
          y: 0,
          text:"",
          fontSize: 20,
          align: 'center',
        });
        return (
          <Layer>
            {/* X axis */}
            <Rect
              x={11.69*document.getElementById('a4div').offsetWidth*0.05}
              y={8.27*document.getElementById('a4div').offsetHeight*0.5}
              width={11.69*document.getElementById('a4div').offsetWidth*0.9}
              height={5}
              fill={"#ECEDED"}
              shadowBlur={1}
            />
            {/* X negative */}
            <Rect
              label="-X"
              x={11.69*document.getElementById('a4div').offsetWidth*0.05 - 20}
              y={8.27*document.getElementById('a4div').offsetHeight*0.5 - ((measureTextSizeHelper.measureSize(XAxisProperties.negative).width - 5) / 2) - 20}
              width={measureTextSizeHelper.measureSize(XAxisProperties.negative).height * 2}
              height={measureTextSizeHelper.measureSize(XAxisProperties.negative).width + 40}
              fill={"#595FAB"}
              shadowBlur={5}
              onMouseEnter={(e)=> {
                stageChangeCursor("vertical-text");
                setEnableSearchBox(false)
              }}
              onMouseLeave={(e)=> {
                stageChangeCursor("crosshair");
                setEnableSearchBox(true);
              }}
              onClick={(event)=>{
                setEnableSearchBox(true)
                openModal(event);
              }}
            />
            <Text
              label="-X"
              x={11.69*document.getElementById('a4div').offsetWidth*0.05 - 5}
              y={8.27*document.getElementById('a4div').offsetHeight*0.5 + (measureTextSizeHelper.measureSize(XAxisProperties.negative).width + 5) / 2}
              rotation={270}
              fontSize={20}
              align={'center'}
              text={XAxisProperties.negative}
              onMouseEnter={(e)=> {
                stageChangeCursor("vertical-text");
                setEnableSearchBox(false)
              }}
              onClick={(event)=>{
                setEnableSearchBox(true)
                openModal(event);
              }}
            />
            {/* X positive */}
            <Rect
              label="+X"
              x={(11.69*document.getElementById('a4div').offsetWidth / 1.05) - 10}
              y={8.27*document.getElementById('a4div').offsetHeight*0.5 - ((measureTextSizeHelper.measureSize(XAxisProperties.positive).width - 5) / 2) - 20}
              width={measureTextSizeHelper.measureSize(XAxisProperties.positive).height * 2}
              height={measureTextSizeHelper.measureSize(XAxisProperties.positive).width + 40}
              fill={"#595FAB"}
              shadowBlur={5}
              onMouseEnter={(e)=> {
                stageChangeCursor("vertical-text");
                setEnableSearchBox(false)
              }}
              onMouseLeave={(e)=> {
                stageChangeCursor("crosshair");
                setEnableSearchBox(true);
              }}
              onClick={(event)=>{
                setEnableSearchBox(true)
                openModal(event);
              }}
            />
            <Text
              label="+X"
              x={(11.69*document.getElementById('a4div').offsetWidth / 1.05) + 15}
              y={8.27*document.getElementById('a4div').offsetHeight*0.5 - (measureTextSizeHelper.measureSize(XAxisProperties.positive).width - 5) / 2}
              rotation={90}
              fontSize={20}
              align={'center'}
              text={XAxisProperties.positive}
              onMouseEnter={(e)=> {
                stageChangeCursor("vertical-text");
                setEnableSearchBox(false)
              }}
              onClick={(event)=>{
                setEnableSearchBox(true)
                openModal(event);
              }}
            />

            {/* Y axis */}
            <Rect
              x={11.69*document.getElementById('a4div').offsetWidth*0.5}
              y={8.27*document.getElementById('a4div').offsetHeight*0.05}
              width={5}
              height={8.27*document.getElementById('a4div').offsetHeight*0.9}
              fill={"#595FAB"}
              shadowBlur={1}
            />
            {/* Y positive */}
            <Rect
              label="+Y"
              x={(11.69*document.getElementById('a4div').offsetWidth*0.5) - ((measureTextSizeHelper.measureSize(YAxisProperties.positive).width - 5) / 2) - 20}
              y={(8.27*document.getElementById('a4div').offsetHeight*0.05) - 20}
              width={measureTextSizeHelper.measureSize(YAxisProperties.positive).width + 40}
              height={measureTextSizeHelper.measureSize(YAxisProperties.positive).height * 2}
              fill={"#ECEDED"}
              shadowBlur={5}
              onMouseEnter={(e)=> {
                stageChangeCursor("text");
                setEnableSearchBox(false)
              }}
              onMouseLeave={(e)=> {
                stageChangeCursor("crosshair");
                setEnableSearchBox(true);
              }}
              onClick={(event)=>{
                setEnableSearchBox(true)
                openModal(event);
              }}
            />
            <Text
              label="+Y"
              x={(11.69*document.getElementById('a4div').offsetWidth*0.5) - (measureTextSizeHelper.measureSize(YAxisProperties.positive).width - 5) / 2}
              y={(8.27*document.getElementById('a4div').offsetHeight*0.05) - 5}
              fontSize={20}
              text={YAxisProperties.positive}
              onMouseEnter={(e)=> {
                stageChangeCursor("text");
                setEnableSearchBox(false)
              }}
              onClick={(event)=>{
                setEnableSearchBox(true)
                openModal(event);
              }}
            />
            {/* Y negative */}
            <Rect
              label="-Y"
              x={(11.69*document.getElementById('a4div').offsetWidth*0.5) - ((measureTextSizeHelper.measureSize(YAxisProperties.negative).width - 5) / 2) - 20}
              y={(8.27*document.getElementById('a4div').offsetHeight / 1.05) - 20}
              width={measureTextSizeHelper.measureSize(YAxisProperties.negative).width + 40}
              height={measureTextSizeHelper.measureSize(YAxisProperties.negative).height * 2}
              fill={"#ECEDED"}
              shadowBlur={5}
              onMouseEnter={(e)=> {
                stageChangeCursor("text");
                setEnableSearchBox(false)
              }}
              onMouseLeave={(e)=> {
                stageChangeCursor("crosshair");
                setEnableSearchBox(true);
              }}
              onClick={(event)=>{
                setEnableSearchBox(true)
                openModal(event);
              }}
            />
            <Text
              label="-Y"
              x={(11.69*document.getElementById('a4div').offsetWidth*0.5) - (measureTextSizeHelper.measureSize(YAxisProperties.negative).width - 5) / 2}
              y={(8.27*document.getElementById('a4div').offsetHeight / 1.05) - 5}
              fontSize={20}
              align={'center'}
              text={YAxisProperties.negative}
              onMouseEnter={(e)=> {
                stageChangeCursor("text");
                setEnableSearchBox(false)
              }}
              onClick={(event)=>{
                setEnableSearchBox(true)
                openModal(event);
              }}
            />
          </Layer>
        );
      }
    }

    return <Axes />
  }

export default Axes;