import classNames from 'classnames';
import { max } from 'lodash';
import React, { Component } from 'react';
import { Group, Image } from 'react-konva';
import remove_image from '../../assets/images/remove.svg' 

const classes = classNames(
  "RemoveBtn"
);

class DraggableLogo extends Component {
    state = {
      image: null,
      removeBtn: null,
    };
    componentDidMount() {
      this.loadImage();
    }
    componentDidUpdate(oldProps) {
      if (oldProps.src !== this.props.src) {
        this.loadImage();
      }
    }
    componentWillUnmount() {
      this.image.removeEventListener('load', this.handleLoad);
    }
    loadImage() {
      // save to "this" to remove "load" handler on unmount
      this.image = new window.Image();
      this.image.crossOrigin = 'Anonymous';
      this.image.src = this.props.src;
      this.image.addEventListener('load', this.handleLoad);

      // removeBtn
      this.removeBtn = new window.Image();
      this.removeBtn.src = remove_image;
      this.removeBtn.addEventListener('load', this.handleremoveBtnLoad);
    }
    handleLoad = () => {
      // after setState react-konva will update canvas and redraw the layer
      // because "image" property is changed
      this.setState({
        image: this.image,
      });
      // if you keep same image object during source updates
      // you will have to update layer manually:
      // this.imageNode.getLayer().batchDraw();
    };
    handleremoveBtnLoad  = () => {
      this.setState({
        removeBtn: this.removeBtn
      });
    }

    render() {
      const maxInchOfLogo = 1;
      const width_image_ratio = this.image ? (document.getElementById('a4div').offsetWidth * maxInchOfLogo) / this.image.width : 0;
      const height_image_ratio = this.image ? (document.getElementById('a4div').offsetHeight * maxInchOfLogo) / this.image.height : 0;
      const smallest_ratio = max([width_image_ratio, height_image_ratio])
      const logoWidth = this.image ? smallest_ratio * this.image.width : 0;
      const logoHeight = this.image ? smallest_ratio * this.image.height : 0;

      return (
        <Group
          x={this.props.x}
          y={this.props.y}
          draggable
          onDragStart={() => {
            this.props.stageChangeCursor("grabbing");
            this.setState({
              isDragging: true
            });
            this.props.setEnableSearchBox(false)
          }}
          onDragEnd={e => {
            this.props.stageChangeCursor("grab");
            this.setState({
              isDragging: false,
              x: e.target.x(),
              y: e.target.y()
            });
            this.props.onChange(this);
          }}
          onMouseEnter={(e)=> {
            this.props.stageChangeCursor("grab");
            this.setState({
              isOver: true
            });
            this.props.setEnableSearchBox(false)
          }}
          onMouseLeave={(e)=> {
            this.props.stageChangeCursor("crosshair");
            this.setState({
              isOver: false
            });
            this.props.setEnableSearchBox(true);
          }}>
          <Image
            class="logoImage"
            image={this.state.image}
            width={logoWidth}
            height={logoHeight}
            shadowBlur={this.state.isDragging ? 20 : 0}
          />
          <Image
            image={this.state.removeBtn}
            x={-13}
            y={-13}
            height={25}
            width={25}
            visible={this.state.isOver ? this.state.isDragging ? false : true : false}
            className={classes}
            onClick={(e) => {
              this.props.onLogoRemoveClick(e)
              this.props.stageChangeCursor("crosshair");
              this.props.setEnableSearchBox(true);
            }}
            onMouseEnter={(e)=> {
              this.props.stageChangeCursor("pointer");
              this.props.setEnableSearchBox(false)
            }}
            onMouseLeave={(e)=> {
              this.props.stageChangeCursor("grab");
            }}
          />
        </Group>
      );
    }
  }

  export default DraggableLogo;