import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Image from '../../elements/Image';
import { useLocation } from 'react-router-dom';

const Logo = ({
  className,
  ...props
}) => {

  let location = useLocation();

  const classes = classNames(
    'brand',
    className
  );

  return (
    <div
      {...props}
      className={classes}
    >
      <h1 className="m-0">
        <Link to={{pathname: '/', state: { prevPath: location.pathname }}}>
          <Image
            style={{height:'50px'}}
            src={require('./../../../assets/images/cm_logo3.svg').default}/>
        </Link>
      </h1>
    </div>
  );
}

export default Logo;