import React, { Component } from 'react';
import Button from './Button';

class ClearbitAutocomplete extends Component {
  static defaultProps = {
      companiesProps: {className: 'companies'},
      companyProps: {className: 'company'},
      inputProps: null,
      onCompanySelect: null,
      placeholder: 'Company name or URL...',
  };

  state = {
    query: '',
    results: [],
    highlightedIndex: null
  };
  
  appendToQuery = (e) => {
    this.setState({ query: e.target.value }, this.queryClearbit);
  };
  
  handleKeyDown = (e) => {
    if (!this.state.results.length) return;
    var currentIndex = this.state.highlightedIndex;
  
    if (e.key === 'ArrowDown'){
      if (currentIndex === null){
        currentIndex = 0;
      } else {
        currentIndex = (currentIndex + 1) % this.state.results.length;
      }
      this.setState({ highlightedIndex: currentIndex });
    }
  
    if (e.key === 'ArrowUp'){
      e.preventDefault();
      if (currentIndex === -1){
        currentIndex = this.state.results.length;
      }
      currentIndex = (currentIndex - 1);
      this.setState({ highlightedIndex: currentIndex });
    }
  
    if (e.key === 'Enter'){
      currentIndex = this.state.highlightedIndex;
      if (currentIndex < 0) return;
      this.onSelect(currentIndex);
    }
  };
  
  queryClearbit = (e) => {
    var query = this.state.query;
    if (query.length === 0){
      this.setState({ results: [], highlightedIndex: null });
      return;
    }
    this.decideImageUploadMethod(query)
  };

  decideImageUploadMethod = (query) => {
    if (this.isURL(query)) {
      let domain = (new URL(query));
      this.updateResults([{"domain": domain.hostname, "logo": query, "name": domain.hostname}])
    } else {
      fetch('https://autocomplete.clearbit.com/v1/companies/suggest?query=' + query)
      .then(function(response){
        if (response.status !== 200){
          return Promise.reject(new Error(response.statusText));
        } else {
          return response.json();
        }
      })
      .then(this.updateResults)
      .catch(function(err){
        console.log(err);
      });
    }
  }
  
  isURL = (str) => {
    const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
  }

  uploadImage = () => {
    document.getElementById('uploadLogoInput').click();
  }

  onUploadLogo = (logoFile) => {
    this.props.onCompanySelect({"domain": "uploadedLogo", "logo": window.URL.createObjectURL(logoFile), "name": "uploadedLogo"})
  }

  onSelect = (index) => {
    var company = this.state.results[index];
    this.setState({query: '', results: []});
    this.props.onCompanySelect(company);
  };
  
  highlightItemFromMouse = (index) => {
    this.setState({highlightedIndex: index});
  };
  
  selectItemFromMouse = (index) => {
    this.onSelect(index);
  };
  
  updateResults = (results) => {
    this.setState({ results: results });
  };
  
  renderResults(){
    var renderedResults = this.state.results.map(function(result, index){
      var companyClassName = this.props.companyProps.className;
      return (
        <div 
          key={index} 
          className={this.state.highlightedIndex === index ? companyClassName + ' selected' : companyClassName}
          onMouseEnter={function(){this.highlightItemFromMouse(index);}.bind(this)}
          onMouseDown={function(){this.selectItemFromMouse(index);}.bind(this)}>
          <img style={{ display: "flex", alignItems: "center" }} crossOrigin='anonymous' src={result.logo} alt=''/>
          <span className={companyClassName + '-name'}>
            {result.name}
          </span>
          <span className={companyClassName + '-domain'}>
            {result.domain}
          </span>
        </div>
      );
    }.bind(this));
    return renderedResults;
  }
  
  render () {
    return (
            <div {...this.props} style={{left: this.props.x, top: this.props.y}}>
              <div style={{display: "flex"}}>
                <input 
                {...this.props.inputProps}
                type="text" 
                ref="input"
                value={this.state.query} 
                autcomplete="off" 
                placeholder={this.props.placeholder}
                onChange={this.appendToQuery}
                onKeyDown={this.handleKeyDown}/>
                {this.state.results.length <= 0 &&
                <div>
                  <Button onClick={this.uploadImage} color="primary">
                    Upload
                  </Button>
                  <input id="uploadLogoInput" type="file" onChange={(event) => {this.onUploadLogo(event.target.files[0])}} 
                  style={{visibility: 'hidden', maxHeight:0, maxWidth:0}}></input>
                </div>
                }
              </div>
              {this.state.results.length > 0 &&
              <div {...this.props.companiesProps}>{this.props.renderResults || this.renderResults()}</div>
              }
            </div>
        );
  }
}

export default ClearbitAutocomplete;