import firebase from 'firebase';
import firebaseStorageUtil from './firebasestorage';

import db from './../index.js'

class firestoreUtil {
    static async saveLogosData(logos){
        if (firebase.auth().currentUser) {
            await Promise.all(logos.map(async (logo) => {
                if (logo.src.includes("blob:http")){
                    let res = await fetch(logo.src);
                    let blob = await res.blob();
                    let blob_uid = logo.src.split("/")[3]
                    var file_url = await firebaseStorageUtil.uploadBlob(blob, blob_uid)
                    logo.src = file_url;
                }
            }));
            await db.collection("maps").doc(firebase.auth().currentUser.uid + '/data/logos').set({
                logos: logos
            });
        }
        else if(logos.length > 0){
            window.sessionStorage.setItem('logos', JSON.stringify(logos))
        }
    }

    static async saveAxisData(axesProperties){
        if (firebase.auth().currentUser) {
            await db.collection("maps").doc(firebase.auth().currentUser.uid + '/data/axes').set(axesProperties);
        }
        else if(axesProperties){
            window.sessionStorage.setItem('axes', JSON.stringify(axesProperties))
        }
    }

    static async getMapData() {
        var session_logos = JSON.parse(window.sessionStorage.getItem('logos'));
        var session_axes = JSON.parse(window.sessionStorage.getItem('axes'));
        if (firebase.auth().currentUser) {
            var logos = await db.collection("maps").doc(firebase.auth().currentUser.uid + '/data/logos').get();
            var axes = await db.collection("maps").doc(firebase.auth().currentUser.uid + '/data/axes').get();
            if (logos.data() || axes.data()){
                if (session_logos || (session_axes && session_axes.x.positive !== "Attribute +X" && session_axes.x.negative !== "Attribute -X" && 
                                        session_axes.y.positive !== "Attribute +Y" && session_axes.y.negative !== "Attribute -Y")) {
                    // has local work and remote work, ask if want to load or continue editing with ConfirmationModal
                    if (window.confirm('A previous map was found on your account, would you like to use the current map instead?')) {
                        // Override remote map with current!
                        window.sessionStorage.clear();
                        return {"logos": session_logos, "axes": {"x": session_axes.x, "y": session_axes.y}};
                    } else {
                        // Load prev map and toss current!
                    }
                }
                window.sessionStorage.clear();
                return {"logos": logos.data().logos, "axes": {"x": axes.data().x, "y": axes.data().y}}
            } else {
                // Doesn't have remote work but logged in so don't ask and continue editing
                window.sessionStorage.clear();
                return {"logos": session_logos, "axes": {"x": session_axes.x, "y": session_axes.y}};
            }
        } 
        // else {
        //     if (session_logos || session_axes) {
        //         return {"logos": session_logos || [], "axes": {"x": session_axes.x, "y": session_axes.y}};
        //     }
        // }
    }
}

export default firestoreUtil;