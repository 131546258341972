import React from 'react';
// import sections
import classNames from 'classnames';
import SectionHeader from '../components/sections/partials/SectionHeader';

const About = ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    pushLeft,
    ...props
    }) => {

    const innerClasses = classNames(
        'features-tiles-inner section-inner pt-0',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
    );

    const outerClasses = classNames(
        'features-tiles section',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
      );

    const sectionHeader = {
        title: 'About us',
        paragraph:  `Hi, we are Idan and Daniel.<br>
        We are tech cousins who love creating new tools and businesses.<br>
        After seeing hundreds of startups presentations, and each having at least one competitive landscape map. <br>
        We've decided it's time to have a cool tool to make it easier!<br>
        A living, breathing map that you can always go back and update.`
    };
    
    return (
        <section
            {...props}
            className={outerClasses}
        >
            <div className="container">
                <div className={innerClasses}>
                    <SectionHeader data={sectionHeader} className="center-content" /><br/>
                    <center>
                    <a href="https://twitter.com/DanielRZiv" target="_blank" rel="noreferrer">@DanielRZiv</a>
                    &nbsp;|&nbsp;
                    <a href="https://www.linkedin.com/in/idanbm/" target="_blank" rel="noreferrer">@IdanBM</a>
                    </center>
                </div>
            </div>
        </section>
    );
}

export default About;