import React, { useEffect } from 'react';
import classNames from 'classnames';
import ReactDOM from 'react-dom';
import { Stage, Layer, Text, Image } from 'react-konva';
import useImage from 'use-image';
import firebase from 'firebase';

import Button from '../components/elements/Button';
import Axes from '../components/elements/Axes';
import AxisModal from '../components/elements/AxisModal';
import DraggableLogo from '../components/elements/DraggableLogo';
import ClearbitAutocomplete from '../components/elements/ClearbitAutocomplete';
import firestoreUtil from '../firebase/firestore.js';

const classes = classNames(
  "MapEditor"
);

const DEFAULT_X_AXIS_PROPS = {name: "X",
                              focus: "+",
                              positive: "Attribute +X",
                              negative: "Attribute -X"};
const DEFAULT_Y_AXIS_PROPS = {name: "Y",
                              focus: "+",
                              positive: "Attribute +Y",
                              negative: "Attribute -Y"};

const Editor = () => {
  const stageRef = React.useRef();
  const [images, setImages] = React.useState([]);
  const [showWatermark, setshowWatermark] = React.useState(true);
  const [enableSearchBox, setEnableSearchBox] = React.useState(true);
  const [AxisModalActive, setAxisModalActive] = React.useState(false);
  const [XAxisProperties, setXAxisProperties] = React.useState(DEFAULT_X_AXIS_PROPS);
  const [YAxisProperties, setYAxisProperties] = React.useState(DEFAULT_Y_AXIS_PROPS);
  const [AxisModalData, setAxisModalData] = React.useState({properties: XAxisProperties,
                                                            setFunction: setXAxisProperties});

  const getLogosJSON = () => {
    return JSON.parse(JSON.stringify(images))
  }

  const getAxisPropertiesJSON = () => {
    return {"x": {"positive": XAxisProperties.positive, "negative": XAxisProperties.negative}, 
            "y": {"positive": YAxisProperties.positive, "negative": YAxisProperties.negative}};
  }

  async function loadMapData() {
    const user = await new Promise((res,rej) => firebase.auth().onAuthStateChanged(res, rej));
    if (user){
      setshowWatermark(false);
    } else {
      setshowWatermark(true);
    }
    let mapData = await firestoreUtil.getMapData();
    if (mapData) {
      setImages(mapData.logos);
      setXAxisProperties(mapData.axes.x);
      setYAxisProperties(mapData.axes.y);
    } 
  }
  
  useEffect(() => {
    document.addEventListener('keydown', keyPress); 
    loadMapData();
  }, []);

  useEffect(() => {
    firestoreUtil.saveAxisData(getAxisPropertiesJSON())
  }, [XAxisProperties, YAxisProperties]);
  
  useEffect(() => {
    firestoreUtil.saveLogosData(getLogosJSON())
  }, [images]);

  const openModal = (e) => {
    document.getElementById("searchBoxPlaceHolder").hidden=true;
    let axis_props = e.target.attrs.label.includes("X") ? XAxisProperties : YAxisProperties; 
    let axis_set_func = e.target.attrs.label.includes("X") ? setXAxisProperties : setYAxisProperties; 
    setAxisModalData({properties: axis_props, setFunction: axis_set_func})
    setAxisModalActive(true);
    e.target.attrs.label.includes("+") ? document.getElementById("positive-axis-input").select() : document.getElementById("negative-axis-input").select();
  }

  const closeModal = (e) => {
    e.preventDefault();
    setAxisModalActive(false);
    firestoreUtil.saveAxisData(getAxisPropertiesJSON());
  }  
  
  const keyPress = (e) => {
    e.keyCode === 27 && document.getElementById("searchBoxPlaceHolder").setAttribute('hidden', 'true');
  }

  function OnStageClick(e) {
    // register event position
    stageRef.current.setPointersPositions(e.evt);
    if (enableSearchBox){
      OpenLogosAutocompleteAtPosition(stageRef)
    }
  }

  function OpenLogosAutocompleteAtPosition(stageRef) {
    // calc position relative to window
    const position = stageRef.current.getPointerPosition();
    position.x += stageRef.current.attrs.container.offsetLeft
    position.y += stageRef.current.attrs.container.offsetTop
    // add search box
    document.getElementById("searchBoxPlaceHolder").hidden=false;
    ReactDOM.render(
      <ClearbitAutocomplete 
          id="autocomplete"
          hidden={false}
          {...position}
          onCompanySelect={(company) => {
            AddImageToLayerAtPosition(stageRef, company.logo, stageRef.current.getPointerPosition());
            document.getElementById("searchBoxPlaceHolder").hidden=true;
          }}
      />,
      document.getElementById("searchBoxPlaceHolder"));
    document.getElementById("autocomplete").getElementsByTagName("input")[0].value = '';
    document.getElementById("autocomplete").getElementsByTagName("input")[0].focus();
  }

  function AddImageToLayerAtPosition(stageRef, logo_src, position) {
    let { norm_x, norm_y } = getNormalizedPosition(stageRef, position);

    // add image
    setImages(
      images.concat([
        {
          ...position,
          norm_x: norm_x,
          norm_y: norm_y,
          src: logo_src
        },
      ])
    );
  }

  function getNormalizedPosition(stageRef, position) {
    const stage_width = stageRef.current.attrs.container.offsetWidth
    const stage_height = stageRef.current.attrs.container.offsetHeight
    // console.log("stage_width: " + stage_width)
    // console.log("stage_height: " + stage_height)
    const center_x = stage_width / 2;
    const center_y = stage_height / 2;
    // console.log("center_x: " + center_x)
    // console.log("center_y: " + center_y)
    const norm_ratio_x = center_x / 100; 
    const norm_ratio_y = center_y / 100; 

    const norm_x = (position.x - center_x) / (norm_ratio_x);
    const norm_y = -(position.y - center_y) / (norm_ratio_y);
    // console.log("norm_x: " + Math.round(norm_x))
    // console.log("norm_y: " + Math.round(norm_y))
    return {norm_x, norm_y};
  }

  function OnStageMouseOver(e) {
    // document.getElementById("searchBoxPlaceHolder").hidden=true;
    stageRef.current.attrs.container.style.cursor = "crosshair";
  }

  function OnExport(e) {
    document.getElementById("searchBoxPlaceHolder").hidden=true;
    var dataURL = stageRef.current.toDataURL({ pixelRatio: 3 });
    downloadURI(dataURL, 'My competitive map.png');
  }

  // function from https://stackoverflow.com/a/15832662/512042
  function downloadURI(uri, name) {
    var link = document.createElement('a');
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    // delete link;
  }

  function OnPrint(e) {
    document.getElementById("searchBoxPlaceHolder").hidden=true;
    var dataUrl = stageRef.current.toDataURL({ pixelRatio: 3 }); //attempt to save base64 string to server using this var  
    var win = window.open('My compatitive map', "_new");
    win.document.open();
    win.document.write([
        '<html>',
        '   <style>@page{size:landscape; margin: 0;}</style><html><head><title></title>',
        '   <head>',
        '   </head>',
        '   <body onload="window.print()" onafterprint="window.close()">',
        '       <img src="' + dataUrl + '" width=\'1080\' height=\'720\'/>',
        '   </body>',
        '</html>'
    ].join(''));
    win.document.close();
  }

  function onClear(e) {
    setImages([]);
    setXAxisProperties(DEFAULT_X_AXIS_PROPS);
    setYAxisProperties(DEFAULT_Y_AXIS_PROPS);
  }

  const [image] = useImage(require('./../assets/images/watermark.png').default);

  return (
    <div>
      <div id="searchBoxPlaceHolder" style={{maxHeight:0, maxWidth:0}}/>
      <div>
        <Button onClick={OnExport} color="low-contrast" style={{position: 'absolute', margin: 20,
          left: ((window.innerWidth - 11.69*document.getElementById('a4div').offsetWidth) / 2.04) + 11.69*document.getElementById('a4div').offsetWidth}}>
          Export PNG
        </Button>
        <Button onClick={OnPrint} color="low-contrast" style={{position: 'absolute', margin: 20, marginTop: 80,
          left: ((window.innerWidth - 11.69*document.getElementById('a4div').offsetWidth) / 2.04) + 11.69*document.getElementById('a4div').offsetWidth}}>
          Print
        </Button>
        <Button onClick={onClear} color="low-contrast" style={{position: 'absolute', margin: 20, marginTop: 140,
          left: ((window.innerWidth - 11.69*document.getElementById('a4div').offsetWidth) / 2.04) + 11.69*document.getElementById('a4div').offsetWidth}}>
          Clear
        </Button>
        <Stage id="MapEditor" onClick={OnStageClick} onMouseOver={OnStageMouseOver} ref={stageRef}
          className={classes}
          width={11.69*document.getElementById('a4div').offsetWidth}
          height={8.27*document.getElementById('a4div').offsetHeight}
          style={{ marginLeft: (window.innerWidth - 11.69*document.getElementById('a4div').offsetWidth) / 2.04,
                   marginRight: (window.innerWidth - 11.69*document.getElementById('a4div').offsetWidth) / 2.04}}
          >
          {showWatermark && <Layer>
            <Image
              image={image}
              width={1200}
              height={800}
            />
          </Layer>}
          <Axes 
            XAxisProperties={XAxisProperties}
            YAxisProperties={YAxisProperties}
            openModal={openModal}
            stageChangeCursor={(cursor_style)=>{
              stageRef.current.attrs.container.style.cursor = cursor_style
            }}
            setEnableSearchBox={setEnableSearchBox}
          />
          <Layer id="logosLayer">
            {images.map((image) => {
              // console.log("x: " + image.x + "y: " + image.y)
              return <DraggableLogo 
                src={image.src}
                x={image.x} 
                y={image.y}
                stageChangeCursor={(cursor_style)=>{
                  stageRef.current.attrs.container.style.cursor = cursor_style
                }}
                setEnableSearchBox={setEnableSearchBox}
                onLogoRemoveClick={(e) => {
                  setImages(images.filter(item => item !== image));
                }}
                onChange={(img) => {
                  image.x = img.state.x;
                  image.y = img.state.y;
                  firestoreUtil.saveLogosData(getLogosJSON());
                }}
                />;
              })
            }
          </Layer>
          <Layer id="copyRightsLayer">
            <Text
                x={11.69*document.getElementById('a4div').offsetHeight - document.getElementById('a4div').offsetHeight - 40}
                y={(8.27*document.getElementById('a4div').offsetHeight / 1.05 + 10)}
                text={"© competitivemap.com"}
              />
          </Layer>
        </Stage>
      </div>
      <AxisModal
        id="axis-modal"
        data={AxisModalData}
        show={AxisModalActive}
        handleClose={closeModal} 
      />
      <div className="container">
        <div style={{fontSize: 14}}
          className="footer-copyright">Logos provided by <a href="https://clearbit.com">Clearbit</a></div>
      </div>
    </div>
  );
}

export default Editor;