import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import firebase from 'firebase';

import App from './App';
import * as serviceWorker from './serviceWorker';

import './App.css';
import './assets/scss/style.scss';

// Use your config values here.
firebase.initializeApp({
  apiKey: "AIzaSyCT4KqMaW3ljShKviAo278I4rdxEm0BWF8",
  authDomain: "competitivemap.firebaseapp.com",
  projectId: "competitivemap",
  storageBucket: "competitivemap.appspot.com",
  messagingSenderId: "1037120305699",
  appId: "1:1037120305699:web:ae6fdee4c4fc03fd738f96",
  measurementId: "G-FPXS87K8QW"
});

var db = firebase.firestore();
// firebase.analytics();

const history = createBrowserHistory();

ReactDOM.render(
  <Router history={history}>
    <App />
  </Router>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

export default db;