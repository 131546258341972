import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import SectionHeader from './../sections/partials/SectionHeader';
import Input from '../elements/Input';
import Button from '../elements/Button';

const MAX_AXIS_PROPERTY_LENGTH = 40;

const propTypes = {
  children: PropTypes.node,
  handleClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  closeHidden: PropTypes.bool,
  data: PropTypes.object
}

const defaultProps = {
  children: null,
  show: false,
  closeHidden: false,
  data: null
}

const AxisModal = ({
  className,
  children,
  handleClose,
  show,
  closeHidden,
  data,
  ...props
}) => {

  useEffect(() => {
    document.addEventListener('keydown', keyPress);
    document.addEventListener('click', stopProgagation);
    return () => {
      document.removeEventListener('keydown', keyPress);
      document.removeEventListener('click', stopProgagation);
    };    
  });

  useEffect(() => {
    handleBodyClass();
  }, [props.show]); 

  const handleBodyClass = () => {
    if (document.querySelectorAll('.modal.is-active').length) {
      document.body.classList.add('modal-is-active');
    } else {
      document.body.classList.remove('modal-is-active');
    }
  }

  const keyPress = (e) => {
    e.keyCode === 27 && handleClose(e);
    e.keyCode === 13 && handleClose(e);
  }

  const stopProgagation = (e) => {
    e.stopPropagation();
  }

  const saveAxis = (e) => {
    data.setFunction({name: data.properties.name,
      positive: document.getElementById("positive-axis-input").value,
      negative: document.getElementById("negative-axis-input").value});
  }

  const classes = classNames(
    'modal',
    show && 'is-active',
    className
  );

  return (
    <>
      {show && 
        <div
          {...props}
          className={classes}
          onClick={handleClose}>
          <div className="modal-inner" onClick={stopProgagation}>
            {
              <>
                {!closeHidden &&
                  <button
                    className="modal-close"
                    aria-label="close"
                    onClick={handleClose}
                  ></button>
                }
                <div className="modal-content">
                  {children}
                  <SectionHeader id="axis-modal-section-header" tag="h3" data={{
                    title: data.properties.name + " Axis editor",
                    paragraph: `TIP: Try to select a determinant attribute consumers depend on to make decisions.<br>
                    For example, it could be the quality of the product or the price of it.`
                  }} className="left-content" />
                  <Input id="positive-axis-input"
                    placeholder="Positive metric ( + )"
                    defaultValue={data.properties.positive}
                    onChange={saveAxis}
                    maxlength={MAX_AXIS_PROPERTY_LENGTH}/>
                  <br/>
                  <Input id="negative-axis-input"
                    placeholder="Negative metric ( - )"
                    defaultValue={data.properties.negative}
                    onChange={saveAxis}
                    maxlength={MAX_AXIS_PROPERTY_LENGTH}/>
                  <br />
                  <Button onClick={(e) => {
                    saveAxis(e);
                    handleClose(e);
                  }} color="primary">
                    Save
                  </Button>
                </div>
              </>
            }
          </div>
        </div>
      }
    </>
  )
}

AxisModal.propTypes = propTypes;
AxisModal.defaultProps = defaultProps;

export default AxisModal;