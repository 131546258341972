import firebase from "firebase";

class firebaseStorageUtil {
    /**
     * @param {File} file 
     */
    static async uploadBlob(file, uid) {
        var file_url = await firebase.auth().currentUser.uid + "/" + uid;
        var upload = await firebase.storage().ref().child(file_url).put(file);
        return await upload.ref.getDownloadURL();
    }
}

export default firebaseStorageUtil;